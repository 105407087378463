import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import MessagesEndpoints from "@serie3/mymediaplanner/Modules/Messages/endpoints";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const Requests = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      await crudApi.getAll(
        MessagesEndpoints.getSentRequests,
        setRequests,
        "requests"
      );
    };
    fetchRequests();
  }, []);

  return (
    <>
      <h4 className="mb-sm-0">{t("Backend.Outreach.Messages.MessagesLog")}</h4>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive react-table">
                <table className="custom-header-css table table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{formatDate(item.send_date)}</td>
                          <td>
                            <a
                              href="{item.url}"
                              class="text-decoration-none"
                              target="_blank"
                            >
                              {item.name}{" "}
                              <small class="text-muted">
                                ({item.follower})
                              </small>
                            </a>
                            <p class="mb-0 mt-1 small">{item.bio}</p>
                          </td>
                          <td style={{ textAlign: "center", color: "red" }}>
                            {item.withdrawn && <FontAwesomeIcon icon={faX} />}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Requests;
