import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import MessagesEndpoints from "@serie3/mymediaplanner/Modules/Messages/endpoints";
import { formatDate } from "@serie3/common/Shared/timeingStuff";

const MessageLog = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessags = async () => {
      await crudApi.getAll(MessagesEndpoints.getSent, setMessages, "messages");
    };
    fetchMessags();
  }, []);

  return (
    <>
      <h4 className="mb-sm-0">{t("Backend.Outreach.Messages.MessagesLog")}</h4>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive react-table">
                <table className="custom-header-css table table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {messages.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{formatDate(item.send_date)}</td>
                          <td>
                            <a href={item.url} target="_blank">{item.name}</a>
                          </td>
                          <td>{item.message}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default MessageLog;
