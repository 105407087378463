import { useTranslation } from "react-i18next";
import { Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import AccountabilityEndpoints from "@serie3/mymediaplanner/Modules/Accountability/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { formatDate } from "@serie3/common/Shared/timeingStuff";

const AccountabilityHistory = ({ props }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetAccountability = async () => {
      await crudApi.getAll(
        AccountabilityEndpoints.index,
        setData,
        AccountabilityEndpoints.dataField
      );
    };
    fetAccountability();
  }, []);

  return (
    <Row>
      <Col>
        <div className="table-responsive react-table">
          <Table className="custom-header-css table table-hover">
            <thead>
              <tr>
                <th>{t("Backend.Accountability.Labels.Valid")}</th>
                <th>{t("Backend.Accountability.Labels.Platform")}</th>
                <th>{t("Backend.Accountability.Labels.Posts")}</th>
                <th>{t("Backend.Accountability.Labels.Requests")}</th>
                <th>{t("Backend.Accountability.Labels.Icebreaker")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((accountability, index) => {
                const [yearTime, month, day ] = accountability.valid.split("-");
                const [year, time] = yearTime.split(", ");
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;

                const isPastMonth =
                  year < currentYear ||
                  (year == currentYear && month < currentMonth);
                return (
                  isPastMonth && (
                    <tr key={accountability.id}>
                      <td>{month + "." + yearTime}</td>
                      <td>{accountability.platform}</td>
                      <td>
                        {accountability.reached_post_amount
                          ? accountability.reached_post_amount
                          : 0}{" "}
                        / {accountability.post_amount}
                      </td>
                      <td>
                        {accountability.reached_request_amount
                          ? accountability.reached_request_amount
                          : 0}{" "}
                        / {accountability.request_amount}
                      </td>
                      <td>
                        {accountability.reached_messages_amount
                          ? accountability.reached_messages_amount
                          : 0}{" "}
                        / {accountability.messages_amount}
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default AccountabilityHistory;
